import { createRouter, createWebHistory } from "vue-router";

const routes = [

  {
    path: "/",
    name: "TokenPage",
    component: () => import("../views/TokenPage.vue"),
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log({ to, from, savedPosition });
    // if(savedPosition){
    //   return savedPosition
    // }
    return {
      top: 0,
    };
  },
});

export default router;
