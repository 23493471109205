<template>
  <div class="">
    <!-- <div class="bttn"> -->
    <div :class="{ 'bttn': true, 'my-classs': clasName }">

      <a :href="`${link}`" :class="{ 'bttn': true, 'my-class': clasName }">{{ btn_text }}</a>
    </div>
  </div>
</template>



<script>
export default {
  name: "CommanButton",
  components: {

  },
  props: {
    btn_text: String,
    clasName: String,
    link: String,
  }
}

</script>



<style scoped lang="scss">
.bttn {
  // padding: 16px 24px;
  // padding: 15px 0;
  
  a {
    display: block;
    border: 0;
    border-radius: 30px;
    background-color: var(--yellowgreen);
    font-weight: 600;
    color: var(--black) !important;
    padding: 15px 24px;
    display: inline-block;
  }
}


@media(min-width:320px) and (max-width:575px) {
  .bttn {
    
    a {
      font-size: 14px;
      padding: 10px 18px;
    }
  }
}

@media(min-width:576px) and (max-width:767px) {
  .bttn {
    // padding: 10px 16px;

    a {
      font-size: 14px;
    }
  }
}
</style>