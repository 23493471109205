<template>
  <!-- <MainHeader /> -->
  
  <!-- chat box html start -->
  <div class="">
    <div class="chat_bx">
      <!-- <div class="close" id="close">
      ×
    </div> -->
      <div class="row mt-5 pt-4">

        <h6 class="text-dark bg-white text-center position-absolute top-0 left-0 w-100 py-4">IMMIGRATION SERVICES DEPT
          HELPDESK!</h6>

        <div class="col-4 text-center chat text-center " v-for="(data_a, index) in  data_a " :key="index">
          <div class="icons mb-4 ">
            <!-- <Icon :icon="data_a.icon" /> -->
            <a :href=data_a.link target="_blank"><img :src="require(`@/assets/TokenPage/${data_a.logo}.png`)" alt=""
                class="img-fluid" loading="lazy"></a>
            <h6 class>{{ data_a.name }}</h6>
          </div>
          <!-- <Icon :icon="data_a.icon" /> -->
        </div>
      </div>
    </div>
  </div>

  <!-- chat box html end -->

 <router-view />
  <!-- <MainFooter /> -->
  <GetInTouch v-show="show" />
  <CommonFooter  v-show="show" />
  <WhatsApp v-show="show" />


  <!-- <LoaderFile /> -->
</template>



<script>
import GetInTouch from '@/components/GetInTouch.vue';
import CommonFooter from '@/components/CommonFooter.vue';
import WhatsApp from '@/components/WhatsApp.vue';

export default {
  name: "App",
  components: {
    GetInTouch,
    CommonFooter,
    WhatsApp,

  },
  data() {
    return {
      show: false,


      data_a: [
        {

          logo: "whatsapp",
          name: "whatsapp",
          link: "https://www.whatsapp.com/",
        },
        {
          logo: "messenger",
          name: "messenger",
          link: "",
        },
        // {
        //   logo: "viber",
        //   name: "viber",
        //   link: "",
        // },
        // {
        //   logo: "wechat",
        //   name: "wechat",
        //   link: "",
        // },
        // {
        //   logo: "viber",
        //   name: "viber",
        //   link: "",
        // },
        {
          logo: "skype",
          name: "skype",
          link: "",
        }
      ]
    }
  },
  async mounted() {


    var Tawk_API = Tawk_API || {};
    // let Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5b6bf0a6e21878736ba2c411/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();

    await new Promise(r => setTimeout(r, 400));
    this.show = true;
 }
}

</script>



<style lang="scss">
/* chat_box css start */

.chat_bx {
  background-color: rgb(234, 234, 234);
  width: 20vw;
  position: fixed;
  top: 50%;
  right: 50%;
  z-index: 99999;
  border-radius: 10px;
  padding: 16px 8px;
  overflow: hidden;
  transform: translate(50%, -50%);

  h6 {
    font-size: 16px;
    right: 0;
  }

  .close {
    font-size: 30px;
    color: #000;
    position: absolute;
    right: 3%;
    top: 0;
    cursor: pointer;
  }

  .icons {

    // width: 80%;

    h6 {
      color: var(--black);
      font-weight: 400;
      font-size: 15px;
      margin: 10px;
    }

  }

  display: none;
}


.show_chat_box {

  display: inline-block;
  cursor: pointer;
  position: fixed;
  bottom: 27vh;
  right: 0;
  z-index: 1;


  h6 {
    background-color: var(--white);
    color: var(--black);
    height: 80px;
    width: 80px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    color: var(--black);
  }
}

/* chat_box css end */




:root {
  --blue: #6BBBFF;
  --white: #fff;
  --black: #000;
  --yellowgreen: #15e49e;
  --gray: #262626;


  --font-family-inter: 'Inter', sans-serif;
  --font-family-noto: 'Noto Serif KR', serif;
  // --font-family-dm-sans: DM Sans, sans-serif;
  // --font-family-Caveat: "Caveat", cursive;
  --font-family-Caveat: "caveat", cursive;

}

@font-face {
  font-family: caveat;
  src: url("./assets/fonts/Caveat.ttf");
}
@font-face {
  font-family: Noto Serif KR;
  src: url("./assets/fonts/NotoSerifKR-Bold.otf");
}



html,
body {
  overflow-x: hidden;
}


.bg_gray {
  background-color: var(--gray);
}

a,
li {
  text-decoration: none !important;
  list-style: none !important;
}

body {
  background-color: var(--black) !important;
  color: var(--white) !important;
  /* font-family: 'Inter', sans-serif !important; */
  font-family: var(--font-family-inter) !important;
}

section {
  padding: 60px 0;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
}

.swiper-pagination-bullet-active {
  width: 20px !important;
  border-radius: 4px !important;
}

.parent-div .swiper-button-next,
.parent-div .swiper-button-prev {
  display: none !important;
}

.my-class {
  background-color: var(--white) !important;
}




/* accordion css */
section.faq .accordion-button:focus {
  box-shadow: unset !important;
}

section.faq .accordion-button:not(.collapsed) {
  box-shadow: unset !important;
  background: #1D1B1B !important;
}

div#collapseOne,
div#collapseTwo,
div#collapseThree,
div#collapseFour,
div#collapseFive {
  background-color: #1d1b1b;
  border-radius: 0 0 15px 15px;
}

section.faq .accordion-collapse p {
  font-size: 14px !important;
}

section.faq .accordion-item {
  color: var(--bs-accordion-color);

}

.accordion {
  position: relative;
}

.accordion:before {
  content: "";
  height: 96%;
  width: 2px;
  background: #464343;
  position: absolute;
  left: -27px;
}

div#collapseOne {
  position: relative;
}

div#collapseOne:after,
div#collapseTwo:after,
div#collapseThree:after,
div#collapseFour:after,
div#collapseFive:after {
  content: "";
  height: 156%;
  width: 1px;
  background: #fff;
  position: absolute;
  left: -27px;
  top: -57px;
}

div#collapseTwo:after {
  height: 36%;
  top: 79px;
}

div#collapseThree:after {
  height: 37%;
  top: 136px;
}

div#collapseFour:after {
  height: 41%;
  top: 205px;
}

div#collapseFive:after {
  height: 41%;
  top: 205px;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed,
section.faq .accordion-button:not(.collapsed) {
  border: unset !important;
  color: #fff !important;
  border-radius: 15px 15px 0 0;
  margin-bottom: 10px;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

section.faq .accordion-button:not(.collapsed) {
  box-shadow: unset !important;
  background: #000;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed {
  background-color: #0e0c0c;
}

section.faq .accordion-button::after {
  filter: invert(0.5);
  padding: 10px 10px;
  border: 1px solid #616161;
}

/* accordion css */


/* crypto css start rh */

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons !important;
  font-size: 12px !important;
  bottom: -250px;
  position: absolute;
  color: var(--white);
  padding: 12px 17px;
  border-radius: 4px;
  background: #414141;
}

.swiper-button-prev:after {
  content: 'prev';
  position: absolute;
  right: -1190px;
}






/* _____________  jy   27/10/23 start__________________ */


.main-div .swiper-pagination-bullet {
  background-color: #15e49e !important;
}

/* _____________  jy   27/10/23 end__________________ */


/* _____________  jy   27/10/31 start__________________ */
.meta_slide .swiper-button-prev:after,
.meta_slide .swiper-button-next:after {
  font-family: swiper-icons !important;
  font-size: 12px !important;
  bottom: -160px !important;
  position: absolute !important;
  color: #fff !important;
  padding: 12px 17px !important;
  border-radius: 4px !important;
  background: #9C9C9C !important;
}

.meta_slide .swiper-button-next:after {
  background-color: #0C0C0C !important;
}

.meta_slide .swiper-wrapper {
  min-height: 400px !important;
}

.meta_slide .swiper-button-next:after {
  right: 0;
}



@media (min-width:320px) and (max-width:575px) {

  .meta_slide .swiper-button-prev:after {
    right: -100px;
  }

  .meta_slide .swiper-button-next:after {
    right: 100px;
  }

  .chat_bx {
    width: 100%;

    h6 {
      font-size: 14px;
    }
  }

  .show_chat_box {
    bottom: 24vh;
  }
}

@media (min-width:576px) and (max-width:767px) {

  .meta_slide .swiper-button-prev:after {
    right: -210px;
  }

  .meta_slide .swiper-button-next:after {
    right: 210px;
  }



  .chat_bx {
    width: 68vw;
  }
}

@media (min-width:768px) and (max-width:991px) {

  .meta_slide .swiper-button-prev:after {
    right: -275px;
  }

  .meta_slide .swiper-button-next:after {
    right: 275px;
  }



  .chat_bx {
    width: 59vw;
  }

  .show_chat_box {
    bottom: 30vh;
  }
}

@media (min-width:992px) and (max-width:1199px) {

  .meta_slide .swiper-button-prev:after {
    right: -830px;
  }

  .meta_slide .swiper-button-next:after {
    right: 0px;
  }

  .chat_bx {
    width: 40vw;
  }

  .show_chat_box {
    bottom: 30vh;
  }
}

@media (min-width:1200px) and (max-width:1399px) {

  .meta_slide .swiper-button-prev:after {
    right: -999px;
  }

  .meta_slide .swiper-button-next:after {
    right: 0px;
  }



  .chat_bx {
    width: 34vw;
  }

  .show_chat_box {
    bottom: 30vh;
  }
}

@media (min-width:1400px) and (max-width:1599px) {
  .chat_bx {
    width: 34vw;
  }
}

@media (min-width:1500px) and (max-width:1699px) {
  .chat_bx {
    width: 26vw;
  }
}

@media (min-width:1700px) and (max-width:1899px) {
  .chat_bx {
    width: 26vw;
  }
}




@media (min-width:1400px) and (max-width:1900px) {

  .meta_slide .swiper-button-next:after {
    right: 0;
  }
}

.meta_slide .swiper-pagination-bullet {
  display: none !important;
}

/* _____________  jy   27/10/21 end__________________ */


/* _____________  jy   27/11/1 start_________________ */

@media (min-width:320px) and (max-width:1900px) {

  .p2p_slide .swiper-button-prev:after,
  .p2p_slide .swiper-button-next:after {
    display: none;
  }
}

/* _____________  jy   27/11/1 end__________________ */






@media all and (min-width:1200px) and (max-width:1399px) {

  .parent-div .swiper-button-prev:after,
  .parent-div .swiper-button-next:after {
    display: none;
  }
}

@media all and (min-width:992px) and (max-width:1199px) {

  .parent-div .swiper-button-prev:after,
  .parent-div .swiper-button-next:after {
    display: none;
  }
}

@media all and (min-width:786px) and (max-width:991px) {

  .parent-div .swiper-button-prev:after,
  .parent-div .swiper-button-next:after {
    display: none;
  }
}

@media all and (min-width:320px) and (max-width:767px) {

  .parent-div .swiper-button-prev:after,
  .parent-div .swiper-button-next:after {
    display: none;
  }

  section {
    padding: 40px 0;
  }

}

/* crypto css end rh */
</style>


