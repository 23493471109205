<template>
    <div>

        <section class="getintouch">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="inner_text">
                            <!-- <TitleHeading title_heaidng="Monthly Stats" /> -->

                            <div class="mb-4">
                                <h1 class="fw-bold text-center text-md-start">Get In Touch</h1>
                            </div>


                            <div class="icons mb-4 mb-md-5 d-none">
                                <ul
                                    class="d-flex flex-wrap justify-content-center justify-content-md-start align-items-center p-0 m-0">
                                    <li>
                                        <router-Link to="">
                                            <Icon icon="ri:facebook-fill" />
                                        </router-Link>
                                    </li>
                                    <li>
                                        <router-Link to="">
                                            <Icon icon="ri:linkedin-fill" />
                                        </router-Link>
                                    </li>
                                    <li>
                                        <router-Link to="">
                                            <Icon icon="ri:instagram-fill" />
                                        </router-Link>
                                    </li>
                                    <li>
                                        <router-Link to="">
                                            <Icon icon="mdi:twitter" />
                                        </router-Link>
                                    </li>
                                    <li>
                                        <router-Link to="">
                                            <Icon icon="mdi:youtube" />
                                        </router-Link>
                                    </li>
                                    <li>
                                        <router-Link to="">
                                            <Icon icon="file-icons:telegram" />
                                        </router-Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div class="inner_text text-center text-md-end">
                            <p class="mb-4">
                                Consult with India's crypto development leaders driving the international blockchain
                                revolution
                            </p>
                            <!-- <p class="mb-4">Get expert advice from a cryptocurrency development company in India, leading the way in global crypto innovation.</p> -->
                            <CommonButton btn_text="Get Started Now" clasName="rohit" link="https://lbmsolution.com/ContactUs" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import { Icon } from '@iconify/vue';
import CommonButton from "@/components/CommonButton"
export default {
    name: "GetinTouch",
    components: {
        CommonButton,
        Icon
    }

}

</script>


<style scoped lang="scss">
.getintouch {
    box-shadow: inset 0 23px 55px #ffffff29;

    .inner_text {
        h1 {
            font-size: 110px;
        }

        p {
            font-family: 'Noto Serif KR', serif;
            font-size: 30px;
        }

        .icons {
            ul {
                li {
                    padding-left: 15px;

                    a {
                        border: 1px solid white;
                        height: 40px;
                        width: 40px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50px;
                        color: #fff;
                        font-size: 21px;
                    }
                }
            }
        }
    }
}


@media(min-width:320px) and (max-width:575px) {
    .getintouch {

        .inner_text {
            p {
                font-size: 15px;
            }

            h1 {
                font-size: 47px;
            }
        }

    }
}

@media(min-width:576px) and (max-width:767px) {


    .getintouch {
        .inner_text {
            h1 {
                font-size: 79px;
            }

            p {
                font-size: 23px;
            }
        }
    }

}


@media(min-width:768px) and (max-width:991px) {

    .getintouch {
        .inner_text {

            h1 {
                font-size: 53px;
            }

            p {
                font-size: 13px;
            }
        }
    }


}

@media(min-width:992px) and (max-width:1199px) {

    .getintouch {
        .inner_text {
            p {
                font-size: 21px;
            }

            h1 {
                font-size: 80px;
            }
        }
    }
}
</style>