<template>
    <div>



        <div v-for="(data_a, index) in data_a" :key="index">
            <!-- <a href=""><i class="fa-brands fa-whatsapp"></i></a> -->
            <a :href="data_a.link" target="_blank" class="pulse">
                <Icon :icon="data_a.icon"></Icon>
            </a>
        </div>
    </div>
</template>




<style scoped lang="scss">
div {
    a {
        width: 60px;
        height: 60px;
        background-color: #30b945;
        position: fixed;
        z-index: 9999999;
        bottom: 10px;
        left: 10px;
        border-radius: 50%;
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: center;


        svg {
            font-size: 50px;
        }
    }
}
</style>


<script>

import { Icon } from '@iconify/vue';
export default {
    name: "WhatsApp",
    components: {
        Icon,
    },
    data() {
        return {
            data_a: [
                {
                    link: "https://api.whatsapp.com/send?phone=918448443318",
                    icon: "logos:whatsapp-icon"
                }
            ]
        }
    }
}
</script>


<style scoped lang="scss">
.pulse {
    animation: animate-pulse 3s linear infinite;
    cursor: pointer
}

@keyframes animate-pulse {
    0% {
        box-shadow: 0 0 0 0 rgb(48, 185, 69), 0 0 0 0 rgb(36, 139, 52);
    }

    40% {
        box-shadow: 0 0 0 50px rgba(255, 109, 74, 0), 0 0 0 0 rgb(48, 185, 69);
    }

    80% {
        box-shadow: 0 0 0 50px rgba(255, 109, 74, 0.0), 0 0 0 30px rgba(255, 109, 74, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 109, 74, 0.0), 0 0 0 30px rgba(255, 109, 74, 0);
    }

}
</style>