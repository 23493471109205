import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { iconify } from '@iconify/vue/dist/iconify.js';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import $ from "jquery";
import VueCountryCode from "vue-country-code";
import "../src/assets/gtm/gtm.js";

window.$ = $;

createApp(App).use(router,iconify,VueCountryCode).use(VueSweetalert2).mount("#app");
